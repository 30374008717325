export const arnprior = {
  data() {
    return {
      rangeTypes: [
        {
          id: 1,
          value: "date-range",
          label: "Date Range",
          has_date_selectors: true,
          has_time_selectors: false,
          has_month_selectors: false,
          has_week_selectors: true,
          has_day_selectors: false,
          is_core_option: false,
        },
        {
          id: 2,
          value: "date-range-with-time",
          label: "Date Range with Time",
          has_date_selectors: true,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: true,
          has_day_selectors: false,
          is_core_option: false,
        },
        {
          id: 3,
          value: "months-range",
          label: "Range of Months",
          has_date_selectors: false,
          has_time_selectors: false,
          has_month_selectors: true,
          has_week_selectors: true,
          has_day_selectors: false,
          is_core_option: false,
        },
        {
          id: 4,
          value: "weeks-range",
          label: "Range of Weeks",
          has_date_selectors: false,
          has_time_selectors: false,
          has_month_selectors: false,
          has_week_selectors: true,
          has_day_selectors: false,
          is_core_option: false,
        },
        {
          id: 5,
          value: "days-range",
          label: "Range of Days",
          has_date_selectors: false,
          has_time_selectors: false,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: true,
          is_core_option: false,
        },
        {
          id: 6,
          value: "time-range",
          label: "Time Range (all week)",
          has_date_selectors: false,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: false,
          is_core_option: false,
        },
        {
          id: 7,
          value: "date-range-with-recurring-time",
          label: "Date Range with Recurring Time",
          has_date_selectors: true,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: false,
          is_core_option: false,
        },
        {
          id: 8,
          value: "monday",
          label: "Monday",
          has_date_selectors: false,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: false,
          is_core_option: true,
        },
        {
          id: 9,
          value: "tuesday",
          label: "Tuesday",
          has_date_selectors: false,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: false,
          is_core_option: true,
        },
        {
          id: 10,
          value: "wednesday",
          label: "Wednesday",
          has_date_selectors: false,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: false,
          is_core_option: true,
        },
        {
          id: 11,
          value: "thursday",
          label: "Thursday",
          has_date_selectors: false,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: false,
          is_core_option: true,
        },
        {
          id: 12,
          value: "friday",
          label: "Friday",
          has_date_selectors: false,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: false,
          is_core_option: true,
        },
        {
          id: 13,
          value: "saturday",
          label: "Saturday",
          has_date_selectors: false,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: false,
          is_core_option: true,
        },
        {
          id: 14,
          value: "sunday",
          label: "Sunday",
          has_date_selectors: false,
          has_time_selectors: true,
          has_month_selectors: false,
          has_week_selectors: false,
          has_day_selectors: false,
          is_core_option: true,
        },
      ],
    };
  },

  methods: {
    getRangeType(type) {
      return this.rangeTypes.find((t) => t.value === type);
    },
  },
};
